<template>
  <div class="box-layout">
    <HeaderFiveEpitonet>
      <img slot="logo" src="../../assets/img/logo/global-logo.jpg" />
    </HeaderFiveEpitonet>

    <!-- Start Portfolio Details Area -->
    <div class="rf-portfolio-details section-ptb-xl">
      <v-container class="container">
        <v-row>
          <v-col sm="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../../assets/img/567x600px/567x600px-Lakatos.jpg"
                  alt="portfolio"
                />
              </div>

              <div class="port-single-thumb mt--30">
                <img
                  src="../../assets/img/567x600px/567x600px-Korlat.jpg"
                  alt="portfolio"
                />
              </div>

            </div>
          </v-col>
          <v-col sm="6" cols="12">
            <div class="rp-port-details-inner sticky-top">
              <div class="content">
                <h3>Lakatos munkák</h3>
                <p>
                  Ha kapuról van szó lehet: kiskapu, egyszárnyú, kétszárnyú, toló, úszó. Mindegyikben közös hogy a közlekedésben segítenek. Arra a kérdésre hogy mégis mekkora és hány kapu lenne az ideális sajnos nincs sablon válasz. Minden házhoz más és más a válasz erre. 
                </p>
                <h4>Kerítés gyártás:</h4>
                <p></p>
                <h4>Acélszerkezet készítése:</h4>
                <p>
                  A vas és acél szerkezetekben tömérdek lehetőség és felhasználási terület lakozik. Az szerkezetek mérsékeltebb anyagi kivitelezés mellett is megőrzik megbízható stabilitásukat. Ami az acél szerkezetek fő előnyei:(sokoldalú felhasználás, gazdaságos és hosszú élet tartalma,szerkezetek gyors felépítése,könnyű anyag társítás).
                </p>
                <h4>Géptartó szerkezetek:</h4>
                <p></p>
                <h4>Korlát gyártása:</h4>
                <p>
                  A kültéri korlátok szerepe a kertépítészetben első sorban terek elválasztása, biztonsági szempontok, esztétika. Fontos a megfelelő anyagválasztási harmónia legyen az anyagok között. Legyen az fa/acél korlátok. 
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFiveEpitonet from "../../components/header/HeaderFourEpitonet";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      HeaderFiveEpitonet,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .portfolio-details-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
